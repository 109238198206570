<template>
    <span>
        <!--Main Page-->
        <b-card header-tag="header" no-body>
            <!--Header-->
            <span slot="header">
                <span class="d-flex flex-row justify-content-between" style="align-items: center;">
                    <!--Title-->
                    <strong style="margin-right: 20px;">Notifications</strong>
                    <!--Add Notification-->
                    <fa-icon :icon="['fas', 'plus']" @click="createModal=true"/>
                </span>
            </span>
            <v-client-table :columns="columns" :data="notifications" :options="tOptions" class="dataTable"
                            style="width: 100%; padding: 20px;">
                <div slot="everyone" slot-scope="props">
                    <span v-if="props.row.everyone === true">True</span>
                    <span v-else>False</span>
                </div>
                <div slot="icon" slot-scope="props">
                    <i :class="props.row.icon"></i>
                </div>
                <div slot="editDelete" slot-scope="props">
                    <span>
                        <fa-icon :icon="['fas', 'pen-to-square']" @click="openEditNotificationModal(props.row)"/>
                        <fa-icon :icon="['fas', 'trash']" @click="openDeleteNotificationModal(props.row)"
                           style="padding-left: 10px;"/>
                    </span>
                </div>
            </v-client-table>
        </b-card>

        <!--Create Notification Modal-->
        <b-modal v-model="createModal" no-close-on-backdrop title="Create Notification" class="modal-dark" size="lg">

            <!--Fields-->
            <b-row>

                <!--Type-->
                <b-col sm="6">
                    <label>Type</label>
                    <b-form-input v-model="notification.type"/>
                </b-col>

                <!--Icon-->
                <b-col sm="6">
                    <label>Icon</label>
                    <b-form-input v-model="notification.icon"
                                  :class="'form-control is-' + (filledOut(notification.icon) ? 'valid' : 'invalid')"/>
                </b-col>

                <!--Message-->
                <b-col sm="12" style="margin-top: 10px;">
                    <label>Message</label>
                    <b-textarea v-model="notification.message"
                                :class="'form-control is-' + (filledOut(notification.message) ? 'valid' : 'invalid')"/>
                </b-col>

                <!--Expiration-->
                <b-col sm="6" style="margin-top: 10px;">
                    <label>Expiration</label>
                    <b-form-input type="number" v-model.number="notification.expiration"/>
                </b-col>

                <!--Everyone-->
                <b-col sm="6" style="margin-top: 10px;">
                    <label>Everyone</label>
                    <br>
                    <b-form-select style="width: 218px; height: 35px; padding: 6px 12px;"
                                   :options="everyoneOptions" v-model="notification.everyone"/>
                </b-col>

                <!--Users-->
                <b-col sm="6" style="margin-top: 10px;">
                    <label>Users</label>
                    <multiselect v-model="notification.users" :options="users" :multiple="true" :close-on-select="false"
                                 :clear-on-select="false" :preserve-search="true" track-by="username" label="username">
                    </multiselect>
                </b-col>

                <!--Groups-->
                <b-col sm="6" style="margin-top: 10px;">
                    <label>Groups</label>
                    <multiselect v-model="notification.groups" :options="groups" :multiple="true"
                                 :close-on-select="false"
                                 :clear-on-select="false" :preserve-search="true" track-by="groupname"
                                 label="groupname">
                    </multiselect>
                </b-col>

            </b-row>

            <!--Footer-->
            <template slot="modal-footer">

                <b-button size="sm" variant="secondary" @click="createModal=false">Cancel</b-button>
                <b-button size="sm" variant="danger" :disabled="createNotificationFormControl()"
                          @click="postNotification">Submit</b-button>

            </template>

        </b-modal>

        <!--Delete Notification Modal-->
        <b-modal v-model="deleteModal" no-close-on-backdrop title="Delete Notification" class="modal-dark" size="lg">

            <p>If you would like to delete this {{ deleteNotificationObject.message }} please type the prompt below.</p>
            <br>
            <p style="margin-bottom: 0">Enter "{{ deleteNotificationObject.message }}":</p>
            <b-form-group description="case sensitive">
                <b-form-input id="basicName2" type="text" v-model="deleteText"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="deleteModal=false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="deleteText !== deleteNotificationObject.message" @click="deleteNotification()"
                          size="sm" variant="danger">
                    Delete
                </b-button>
            </template>

        </b-modal>

        <!--Edit Notification Modal-->
        <b-modal v-model="editModal" no-close-on-backdrop title="Edit Notification" class="modal-dark" size="lg">

            <!--Fields-->
            <b-row>

                <!--Type-->
                <b-col sm="6">
                    <label>Type</label>
                    <b-form-input v-model="editNotificationObject.type"/>
                </b-col>

                <!--Icon-->
                <b-col sm="6">
                    <label>Icon</label>
                    <b-form-input v-model="editNotificationObject.icon"/>
                </b-col>

                <!--Message-->
                <b-col sm="12" style="margin-top: 10px;">
                    <label>Message</label>
                    <b-textarea v-model="editNotificationObject.message"/>
                </b-col>

                <!--Expiration-->
                <b-col sm="6" style="margin-top: 10px;">
                    <label>Expiration</label>
                    <b-form-input type="number" v-model.number="editNotificationObject.expiration"/>
                </b-col>

                <!--Everyone-->
                <b-col sm="6" style="margin-top: 10px;">
                    <label>Everyone</label>
                    <br>
                    <b-form-select style="width: 218px; height: 35px; padding: 6px 12px;"
                                   :options="everyoneOptions" v-model="editNotificationObject.everyone"/>
                </b-col>

                <!--Users-->
                <b-col sm="6" style="margin-top: 10px;">
                    <label>Users</label>
                    <multiselect v-model="editNotificationObject.users" :options="users" :multiple="true"
                                 :close-on-select="false"
                                 :clear-on-select="false" :preserve-search="true" track-by="username" label="username">
                    </multiselect>
                </b-col>

                <!--Groups-->
                <b-col sm="6" style="margin-top: 10px;">
                    <label>Groups</label>
                    <multiselect v-model="editNotificationObject.groups" :options="groups" :multiple="true"
                                 :close-on-select="false"
                                 :clear-on-select="false" :preserve-search="true" track-by="groupname"
                                 label="groupname">
                    </multiselect>
                </b-col>

            </b-row>

            <!--Footer-->
            <template slot="modal-footer">

                <b-button size="sm" variant="secondary" @click="editModal=false">Cancel</b-button>
                <b-button size="sm" variant="danger" @click="editNotification">Submit</b-button>

            </template>

        </b-modal>

    </span>

</template>

<script>
import Vue from 'vue';
import fcr from "@/services/fcr";
import iss from "@/services/iss";
import {ClientTable} from 'vue-tables-2';
import Multiselect from 'vue-multiselect';

Vue.use(ClientTable);

export default {
    name: "Notifications",

    components: {
        Multiselect
    },

    created() {
        this.getNotifications();
        this.getUsers();
        this.getGroups();
    },

    data() {
        return {
            columns: ['type', 'message', 'icon', 'expiration', 'everyone', 'editDelete'],
            createModal: false,
            everyoneOptions: [
                {value: false, text: 'False'},
                {value: true, text: 'True'}
            ],
            deleteModal: false,
            deleteNotificationObject: {},
            deleteText: '',
            editModal: false,
            editNotificationObject: {},
            groups: [],
            notifications: [],
            tOptions: {
                sortable: ['type', 'message', 'icon', 'expiration', 'everyone'],
                filterable: ['type', 'message', 'icon', 'expiration', 'everyone'],
                // orderBy: {column: 'created_date', ascending: true},
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                headings: {
                    type: 'Type',
                    message: 'Message',
                    icon: 'Icon',
                    expiration: 'Expiration',
                    everyone: 'Everyone',
                    editDelete: 'Edit/Delete'
                },
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            users: [],

            // fields
            notification: {
                type: '',
                message: '',
                icon: '',
                expiration: null,
                users: [],
                groups: [],
                everyone: false
            }
        }
    },

    methods: {
        createNotificationFormControl() {
            return this.notification.message === '' || this.notification.icon === '';

        },
        deleteNotification() {
            this.deleteModal = false;
            fcr.deleteNotification(this.deleteNotificationObject).then(response => {
                this.$mToast({
                    title: 'Success',
                    text: 'Successfully Deleted Notification',
                    style: 'success'
                });
                this.getNotifications();
            }).catch(() => {
                this.$mToast({
                    title: ' Error',
                    text: 'Error Deleting Notification',
                    style: 'error'
                });
            });
        },
        editNotification() {
            this.editModal = false;
            fcr.editNotification(this.editNotificationObject).then(response => {
                this.$mToast({
                    title: 'Success',
                    text: 'Successfully Edited Notification',
                    style: 'success'
                });
                this.getNotifications();
            }).catch(() => {
                this.$mToast({
                    title: ' Error',
                    text: 'Error Editing Notification',
                    style: 'error'
                });
            });
        },
        filledOut(i) {
            return !(i === null || i === undefined || i === '');

        },
        getGroups() {
            iss.getAllGroups().then(response => {
                this.groups = response.data;
            });
        },
        getNotifications() {
            fcr.getAllNotifications().then(response => {
                this.notifications = response.data
            });
        },
        getUsers() {
            iss.getUsersInGroup().then(response => {
                this.users = response.data;
            });
        },
        openDeleteNotificationModal(notification) {
            this.deleteModal = true;
            this.deleteText = "";
            this.deleteNotificationObject = JSON.parse(JSON.stringify(notification));
        },
        openEditNotificationModal(notification) {
            this.editModal = true;
            this.editNotificationObject = JSON.parse(JSON.stringify(notification));
        },
        postNotification() {
            this.createModal = false;
            new Promise((resolve, reject) => {
                this.notification.users.forEach((user, index, array) => {
                    this.notification.users[index] = user._id;
                    if (index === array.length - 1) resolve();
                });
                if(this.notification.users.length === 0) resolve();
            }).then(() => {
                new Promise((resolve, reject) => {
                    this.notification.groups.forEach((group, index, array) => {
                        this.notification.groups[index] = group._id;
                        if (index === array.length - 1) resolve();
                    });
                    if(this.notification.groups.length === 0) resolve();
                }).then(() => {
                    fcr.postNotification(this.notification).then(response => {
                        this.$mToast({
                            title: 'Success',
                            text: 'Successfully Created Notification',
                            style: 'success'
                        });
                        this.getNotifications();
                        this.notification.type = '';
                        this.notification.message = '';
                        this.notification.icon = '';
                        this.notification.expiration = null;
                        this.notification.users = [];
                        this.notification.groups = [];
                        this.notification.everyone = false;
                    }).catch(() => {
                        this.$mToast({
                            title: ' Error',
                            text: 'Error Creating Notification',
                            style: 'error'
                        });
                    });
                });
            })
        }
    }
}
</script>

<style scoped>

</style>

<style src="../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>